import { badgeClassname } from "./badge.styles";
import { BadgeProps, BadgeType } from "./badge.types";

export const Badge: React.FC<BadgeProps> = ({
  type = BadgeType.brand,
  value = 0,
}) => {
  const hasMoreThanTwoDigits = value >= 100;
  const hasTwoDigits = !hasMoreThanTwoDigits && value >= 10;

  const formattedValue = hasMoreThanTwoDigits ? "+99" : value;

  return (
    <div className={badgeClassname({ type, hasTwoDigits })}>
      {formattedValue}
    </div>
  );
};
