import { HeaderActions, JobMenu } from "./components";
import { JobListingsProvider } from "./context";

export const JobListings = () => {
  return (
    <JobListingsProvider>
      <div className="flex flex-1 flex-col gap-2 overflow-y-auto overflow-x-hidden p-4">
        <HeaderActions />
        <JobMenu />
      </div>
    </JobListingsProvider>
  );
};
