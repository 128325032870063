import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";
import { BadgeType } from "./badge.types";

export const badgeClassname = tv({
  base: cn(
    "flex shrink-0 justify-center items-center gap-1",
    "min-w-[19px] min-h-[19px] w-fit h-fit py-[2px] px-[6px]",
    "font-semibold text-[10px] leading-[10px] tracking-[-0.3px]",
    "rounded-[22px]",
  ),
  variants: {
    type: {
      [BadgeType.brand]: "bg-brand-primary-dark-00 text-neutral-on-brand",
      [BadgeType.danger]: "bg-status-critical text-neutral-on-brand",
      [BadgeType.subtle]: "bg-neutral-00 text-neutral-primary",
    },
    hasTwoDigits: {
      true: "px-[4px]",
    },
  },
});
