import React from "react";
import { TabsList, TabsTrigger, TabsRoot, TabsContent } from "./tabs-base";
import { TabsProps } from "./tabs.types";
import Link from "next/link";

export const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const defaultTabId = tabs.find(tab => tab.isActive)?.id ?? tabs[0].id;

  return (
    <TabsRoot
      className="flex w-full flex-col gap-4"
      defaultValue={defaultTabId}
    >
      <TabsList aria-label="Manage your account">
        {tabs.map(tab => (
          <TabsTrigger
            key={`tab-header-${tab.id}`}
            value={tab.id}
            asChild={tab.href != null}
          >
            {tab.href != null ? (
              <Link href={tab.href}>{tab.title}</Link>
            ) : (
              tab.title
            )}
          </TabsTrigger>
        ))}
      </TabsList>

      {tabs.every(tab => tab.href == null) && (
        <div className="flex flex-grow overflow-x-hidden">
          {tabs.map(tab => (
            <TabsContent
              className="w-full"
              key={`tab-content-${tab.id}`}
              value={tab.id}
            >
              {tab.content}
            </TabsContent>
          ))}
        </div>
      )}
    </TabsRoot>
  );
};
