import { useContext } from "react";

import { JobListingsContext } from ".";

export const useJobListings = () => {
  const { sortBy, changeSortBy } = useContext(JobListingsContext);

  return {
    sortBy,
    changeSortBy,
  };
};
