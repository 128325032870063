import React from "react";
import { AlertProps } from "./alert.types";

export const Alert: React.FC<AlertProps> = ({ message, title }) => {
  return (
    <div className="w-full rounded-xl bg-brand-primary-light-10 p-4">
      <div className="border-l-[3px] border-brand-primary-rest pl-2">
        <h2 className="text-sm font-medium leading-6 text-neutral-primary">
          {title}
        </h2>
        <p className="text-sm font-normal leading-6 tracking-tighter text-neutral-primary">
          {message}
        </p>
      </div>
    </div>
  );
};
