"use client";

import * as React from "react";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { DateRange } from "react-day-picker";

import { cn } from "~/utils/cn";

import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Button, ButtonKind } from "../button";
import { DaySelector } from "./components/day-selector";
import { DatepickerMode, DatePickerProps } from "./date-picker.types";
import { datePickerClassName } from "./date-picker.styles";
import {
  DEFAULT_FORMATING,
  ONE_MONTH,
  TWO_MONTHS,
} from "./date-picker.constants";

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  onSelect,
  mode = DatepickerMode.Single,
  className,
  ariaLabel,
  error,
  label,
  required,
}) => {
  const formatSingleDate = (date: Date) =>
    date ? (
      format(new Date(date), DEFAULT_FORMATING, { locale: enUS })
    ) : (
      <span className="text-neutral-tertiary">Pick a date</span>
    );

  const formatRangeDate = (date: DateRange) =>
    date && date.from && date.to ? (
      `${format(date.from, "PP")} - ${format(date.to, "PP")}`
    ) : (
      <span className="text-neutral-tertiary">Pick a date</span>
    );

  return (
    <Popover>
      <div className="w-full">
        {label && (
          <span className="text-sm/6 font-normal text-neutral-primary">
            {label}
            {required && "*"}
          </span>
        )}
        <PopoverTrigger asChild>
          <Button
            kind={ButtonKind.combobox}
            role="combobox"
            aria-label={ariaLabel}
            disabled={false}
            className={cn(datePickerClassName({ error: !!error }), className)}
          >
            <i className="fa-regular fa-calendar" />
            {mode === DatepickerMode.Single
              ? formatSingleDate(date as Date)
              : formatRangeDate(date as DateRange)}
          </Button>
        </PopoverTrigger>
        {!!error && (
          <span className="mt-1 text-xs text-status-critical">{error}</span>
        )}
        <PopoverContent className="z-[1100] w-auto bg-white p-0" align="start">
          {/* @ts-ignore */}
          <DaySelector
            mode={mode}
            selected={date as any}
            // @ts-ignore
            onSelect={onSelect}
            disabled={date => date < new Date("1900-01-01")}
            initialFocus
            numberOfMonths={
              mode === DatepickerMode.Range ? TWO_MONTHS : ONE_MONTH
            }
          />
        </PopoverContent>
      </div>
    </Popover>
  );
};
