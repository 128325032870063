"use client";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useSidebar,
} from "~/scalis-components/core";
import logger from "~/utils/logger";

import { SORT_BY_OPTIONS } from "../../../../job-listings.constants";

export const SortByMenu = () => {
  const { setFocused } = useSidebar();

  const handleSortBy = (sortBy: string) => {
    logger.info(sortBy);
  };

  return (
    <DropdownMenu onOpenChange={setFocused}>
      <DropdownMenuTrigger asChild>
        <Button
          className="rounded-[4px] bg-transparent p-1 text-lg text-icon-neutral-10 ring-0 hover:bg-button-subtle-hover"
          startAdornment="fa-regular fa-filter"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-neutral-00 p-2">
        {SORT_BY_OPTIONS.map(option => (
          <DropdownMenuItem
            key={option.value}
            className="cursor-pointer justify-between gap-2 rounded-lg p-2 hover:bg-neutral-20"
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
