import { LEVELS } from "../job-entry";
import { JobMenuItemProps } from "./job-menu-item.types";

export const getHref = (
  level: JobMenuItemProps["level"],
  id: number,
  jobId: number,
  milestoneId?: number,
  pipelineId?: number,
) => {
  switch (level) {
    case LEVELS.job:
      return `/company/j/${id}?jobId=${jobId}`;
    case LEVELS.pipeline:
      return `/company/pipeline/${jobId}?pipelineId=${pipelineId}&jobId=${jobId}`;
    case LEVELS.stage:
      return `/company/pipeline?jobId=${jobId}&stageId=${id}&milestoneId=${milestoneId}`;
    default:
      "/";
  }
};
