import React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";

interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {}

export const Label: React.FC<LabelProps> = ({ children, ...props }) => {
  return (
    <LabelPrimitive.Root className="text-sm/6 text-neutral-primary" {...props}>
      {children}
    </LabelPrimitive.Root>
  );
};
