"use client";

import { useRouter } from "next/navigation";
import * as React from "react";

import { Button } from "~/scalis-components/core";

export const CreateJobButton: React.FC = () => {
  const router = useRouter();

  const handleCreateJobListing = () => {
    router.push("/company/job/post");
  };

  return (
    <Button
      className="rounded-[4px] bg-transparent p-1 text-lg text-icon-neutral-10 hover:bg-button-subtle-hover"
      startAdornment="fa-regular fa-plus"
      onClick={handleCreateJobListing}
    />
  );
};
