"use client";

import { createContext, useState } from "react";

import { SORT_BY_NEWEST_TO_OLDEST } from "../job-listings.constants";
import {
  JobListingsContextProps,
  JobListingsProviderProps,
} from "./job-listings-context.types";

export const JobListingsContext = createContext({} as JobListingsContextProps);

export const JobListingsProvider: React.FC<JobListingsProviderProps> = ({
  children,
}) => {
  const [sortBy, setSortBy] = useState(SORT_BY_NEWEST_TO_OLDEST);

  const changeSortBy = (newSortBy: string) => {
    setSortBy(newSortBy);
  };

  return (
    <JobListingsContext.Provider
      value={{
        sortBy,
        changeSortBy,
      }}
    >
      {children}
    </JobListingsContext.Provider>
  );
};
