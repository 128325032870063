import { ButtonHTMLAttributes } from "react";

export enum BadgeType {
  subtle = "subtle",
  brand = "brand",
  danger = "danger",
}

export interface BadgeProps
  extends Omit<Partial<ButtonHTMLAttributes<HTMLButtonElement>>, "type"> {
  type?: BadgeType;
  value?: number;
}
