import {
  Divider,
  SidebarContextProvider,
  SidebarWrapperProps,
} from "~/scalis-components/core";
import { CollapseButton, Container, JobListings, NavItem } from "./components";
import { NAV_ITEMS } from "./sidebar.constants";

export const SidebarWrapper = ({
  children,
  className,
}: SidebarWrapperProps) => {
  return (
    <SidebarContextProvider>
      <Container className={className}>{children}</Container>
    </SidebarContextProvider>
  );
};

export const DefaultSidebar = () => {
  return (
    <SidebarWrapper>
      <ul className="p-2">
        {Object.values(NAV_ITEMS).map(item => (
          <NavItem
            key={item.label}
            href={item.href}
            icon={item.icon}
            label={item.label}
            activePath={item?.activePath}
          />
        ))}
      </ul>
      <Divider />
      <JobListings />
      <Divider />
      <div className="p-2">
        <CollapseButton />
      </div>
    </SidebarWrapper>
  );
};
