"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import React from "react";

import Spinner from "~/components/spinner";
import { Input } from "~/scalis-components/core";
import { useGetUser } from "~/hooks/businessUsers/useGetUser";
import { useGetJobOpenings } from "~/hooks/jobOpening/useGetJobOpenings";
import useDebounce from "~/hooks/useDebounce";
import { useSidebar } from "~/scalis-components/core/sidebar";
import { AppMetadata } from "~/types/auth";
import { cn } from "~/utils/cn";
import { JobEntry } from "..";

export const JobMenu: React.FC = () => {
  const { collapsed, isActive, setFocused } = useSidebar();

  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 500);

  const { user } = useUser();

  const { data: userData, isLoading: loadingUser } = useGetUser(
    (user?.app_metadata as AppMetadata)?.id,
  );

  const { data, isLoading: loadingJobs } = useGetJobOpenings(
    {
      limit: 50,
      filters: { statusIn: [1], companyId: userData?.companyId },
      showPublic: true,
      search: debouncedSearch,
    },
    undefined,
    !!userData,
  );

  const loading = loadingUser || loadingJobs;

  return (
    <div
      className={cn("flex flex-1 overflow-auto", {
        hidden: collapsed && !isActive,
      })}
    >
      <div className="flex h-full w-full flex-col gap-2 overflow-y-hidden overflow-x-clip">
        <Input
          startAdornment={{ icon: "fa-regular fa-search" }}
          placeholder="Search"
          medium
          value={search}
          onChange={e => setSearch(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />

        <ul className="flex h-full flex-1 flex-col gap-1 overflow-y-auto overflow-x-clip">
          {loading ? (
            <Spinner className="relative" />
          ) : (
            <div className="flex flex-col gap-2 px-4">
              {data?.docs?.map(job => <JobEntry key={job.id} {...job} />)}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};
