import { tv } from "tailwind-variants";

export const radioGroupStyles = tv({
  slots: {
    container:
      "relative flex items-center justify-center w-full border border-neutral-10 rounded-xl p-3",
    iconContainer: "flex flex-col py-2 flex-1 items-center",
    label: "select-none text-sm/6 font-normal text-neutral-primary select-none",
    radioButton: "absolute top-3 left-3",
  },
  variants: {
    checked: {
      true: {
        container: "bg-neutral-20",
      },
    },
    disabled: {
      true: {
        label: "text-neutral-disabled",
      },
    },
  },
});
