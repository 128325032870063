"use client";

import { useSearchParams } from "next/navigation";
import React from "react";

import { JobMenuItem, JobMenuItemProps } from "../job-menu-item";
import { LEVELS } from "./job-entry.constants";
import { JobEntryProps } from "./job-entry.types";

export const JobEntry: React.FC<JobEntryProps> = ({
  id,
  job_title,
  Pipeline,
}) => {
  const searchParams = useSearchParams();

  const jobId = searchParams?.get("jobId");
  const stageId = searchParams?.get("stageId");
  const milestoneId = searchParams?.get("milestoneId");

  const stages = Pipeline?.milestones?.flatMap?.(milestone =>
    milestone.stages.map(stage => ({
      id: stage.id,
      label: stage.name,
      level: LEVELS.stage as JobMenuItemProps["level"],
      milestoneId: milestone.id,
      milestoneType: milestone?.milestoneType as string,
      pipelineId: Pipeline.id,
    })),
  );

  const subItems = Pipeline
    ? [
        {
          id: Pipeline.id,
          pipelineId: Pipeline.id,
          label: Pipeline.name,
          level: LEVELS.pipeline as JobMenuItemProps["level"],
          subItems: stages,
        },
      ]
    : undefined;

  const selectedItem = {
    stage: Number(stageId),
    milestone: Number(milestoneId),
    job: Number(jobId),
    pipeline: Pipeline?.id,
  };

  return (
    <JobMenuItem
      id={id}
      label={job_title}
      level={LEVELS.job as JobMenuItemProps["level"]}
      subItems={subItems}
      jobId={id}
      selectedItem={selectedItem}
      pipelineId={Pipeline?.id}
    />
  );
};
