import { Icon } from "../icon";
import {
  AccordionContent,
  AccordionGroup,
  AccordionItem,
  AccordionTrigger,
} from "./accordion-group";
import { AccordionProps } from "./accordion.types";

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  startAdornment,
  actions = [],
  defaultOpen = true,
  collapsible = true,
}) => {
  return (
    <AccordionGroup
      type="single"
      collapsible={collapsible}
      defaultValue={defaultOpen ? "item-1" : ""}
    >
      <AccordionItem value="item-1">
        <div className="flex flex-1 items-center justify-between p-4 py-2 text-sm font-medium md:py-4">
          <div className="flex flex-row gap-2">
            {startAdornment}

            <h2 className="text-sm font-medium leading-6 tracking-tighter">
              {title}
            </h2>
          </div>

          <div className="flex items-center gap-2">
            {actions.length > 0 && (
              <div className="flex items-center gap-1">
                {actions.map(({ icon, onClick, ...props }) => (
                  <button
                    key={icon}
                    type="button"
                    onClick={onClick}
                    aria-label={props?.["aria-label"]}
                  >
                    <Icon icon={icon} />
                  </button>
                ))}
              </div>
            )}

            {collapsible && <AccordionTrigger />}
          </div>
        </div>

        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </AccordionGroup>
  );
};
